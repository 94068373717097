// import React, { useEffect, useState } from 'react';
// import Select from 'react-select';
// import { collection, query, where, getDocs, updateDoc, doc } from 'firebase/firestore';
// import { db } from './firebase';
// import './Dashboard.css';
// import logoEmpresa from './assets/logo_empresa.png';
// import logoCliente from './assets/logo.png';

// const sucursalesOptions = [
  // { value: 'America', label: 'America' },
  // { value: 'Ávila', label: 'Ávila' },
  // { value: 'Bello monte', label: 'Bello monte' },
  // { value: 'Carlota Plaza', label: 'Carlota Plaza' },
  // { value: 'CCCT', label: 'CCCT' },
  // { value: 'Cerro Verde', label: 'Cerro Verde' },
  // { value: 'Chacao', label: 'Chacao' },
  // { value: 'Chuao', label: 'Chuao' },
  // { value: 'Colinas de Tamanaco', label: 'Colinas de Tamanaco' },
  // { value: 'Concresa', label: 'Concresa' },
  // { value: 'Country Club', label: 'Country Club' },
  // { value: 'El Cafetal', label: 'El Cafetal' },
  // { value: 'El Hatillo', label: 'El Hatillo' },
  // { value: 'El Rosal', label: 'El Rosal' },
  // { value: 'La Boyera', label: 'La Boyera' },
  // { value: 'La Castellana', label: 'La Castellana' },
  // { value: 'La Lagunita', label: 'La Lagunita' },
  // { value: 'La Tahona', label: 'La Tahona' },
  // { value: 'Las Mercedes', label: 'Las Mercedes' },
  // { value: 'Los Campitos', label: 'Los Campitos' },
  // { value: 'Los Chorros', label: 'Los Chorros' },
  // { value: 'Los Dos Caminos', label: 'Los Dos Caminos' },
  // { value: 'Los Naranjos VIP', label: 'Los Naranjos VIP' },
  // { value: 'Los Ruices', label: 'Los Ruices' },
  // { value: 'Los Samanes', label: 'Los Samanes' },
  // { value: 'Macaracuay', label: 'Macaracuay' },
  // { value: 'Multicentro', label: 'Multicentro' },
  // { value: 'Parque Cristal', label: 'Parque Cristal' },
  // { value: 'Paseo Vip El Hatillo', label: 'Paseo Vip El Hatillo' },
  // { value: 'Piramide Invertida', label: 'Piramide Invertida' },
  // { value: 'Prados del Este', label: 'Prados del Este' },
  // { value: 'Santa Paula', label: 'Santa Paula' },
  // { value: 'Terras Plaza', label: 'Terras Plaza' },
  // { value: 'Terrazas del Avila', label: 'Terrazas del Avila' },
  // { value: 'Torre Humboldt', label: 'Torre Humboldt' },
  // { value: 'Caurimare', label: 'Caurimare' },
// ];

// const Dashboard = ({ user, onLogout }) => {
//   const [usuarios, setUsuarios] = useState([]);
//   const [filteredUsuarios, setFilteredUsuarios] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);
//   const [searchTerm, setSearchTerm] = useState('');
//   const [saving, setSaving] = useState(false);

//   useEffect(() => {
//     const fetchUsuarios = async () => {
//       try {
//         const q = query(
//           collection(db, "users"),
//           where("user_name", ">=", "rentahouse"),
//           where("user_name", "<", "rentahouse" + "\uf8ff")
//         );

//         const querySnapshot = await getDocs(q);
//         const usuariosData = querySnapshot.docs.map(doc => ({
//           id: doc.id,
//           ...doc.data()
//         }));
//         setUsuarios(usuariosData);
//         setFilteredUsuarios(usuariosData);
//       } catch (err) {
//         console.error("Error al obtener los usuarios:", err);
//         setError("Error al obtener los usuarios.");
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchUsuarios();
//   }, []);

//   const handleSaveSucursal = async (userId, selectedSucursal) => {
//     setSaving(true);
//     try {
//       const userDoc = doc(db, 'users', userId);
//       await updateDoc(userDoc, { sucursal: selectedSucursal.value });
//       alert('Sucursal guardada exitosamente.');
//     } catch (err) {
//       console.error("Error al guardar la sucursal:", err);
//       alert("Error al guardar la sucursal.");
//     } finally {
//       setSaving(false);
//     }
//   };
//   // Función para formatear fechas
//     const formatDate = (timestamp) => {
//       if (!timestamp) return 'N/A';
//       const date = new Date(timestamp.seconds * 1000); // Convierte Firestore Timestamp a Date
//       return date.toLocaleString(); // Formatea según la configuración regional
//     };

//   const handleSearch = (e) => {
//     const term = e.target.value.toLowerCase();
//     setSearchTerm(term);

//     if (!term) {
//       setFilteredUsuarios(usuarios);
//     } else {
//       const filtered = usuarios.filter(usuario =>
//         (usuario.email && usuario.email.toLowerCase().includes(term)) ||
//         (usuario.user_name && usuario.user_name.toLowerCase().includes(term)) ||
//         (usuario.display_name && usuario.display_name.toLowerCase().includes(term))
//       );
//       setFilteredUsuarios(filtered);
//     }
//   };

//   return (
//     <div className="dashboard-container">
//       {/* Header */}
//       <header className="dashboard-header">
//         <img src={logoEmpresa} alt="Logo Empresa" className="header-logo" />
//         <div className="search-container">
//           <input
//             type="text"
//             placeholder="Buscar usuarios"
//             value={searchTerm}
//             onChange={handleSearch}
//             className="search-input small-search"
//             aria-label="Buscar usuarios"
//           />
//         </div>
//         <img src={logoCliente} alt="Logo Cliente" className="header-logo2" />
//       </header>

//       {/* Contenido */}
//       <div className="dashboard-content">
//         <h3>Lista de Usuarios Rentahouse</h3> <p>Total de usuarios: {filteredUsuarios.length}</p>
//         <table className="usuarios-table">
//           <thead>
//             <tr>
//               <th>Correo</th>
//               <th>Nombre de Usuario</th>
//               <th>Display Name</th>
//               <th>Fecha de Creación</th>
//               <th>Sucursal</th>
//               <th>Acción</th>
//             </tr>
//           </thead>
//           <tbody>
//             {filteredUsuarios.length > 0 ? (
//               filteredUsuarios.map(usuario => (
//                 <tr key={usuario.id}>
//                   <td>{usuario.email}</td>
//                   <td>{usuario.user_name}</td>
//                   <td>{usuario.display_name}</td>
//                   <td>{usuario.created_time ? formatDate(usuario.created_time) : 'N/A'}</td>
//                   <td>
//                     <Select
//                       options={sucursalesOptions}
//                       placeholder="Selecciona una sucursal"
//                       defaultValue={usuario.sucursal ? { value: usuario.sucursal, label: usuario.sucursal } : null}
//                       onChange={(option) => handleSaveSucursal(usuario.id, option)}
//                       isDisabled={saving}
//                     />
//                   </td>
//                   <td>
//                     <button
//                       className="action-button"
//                       onClick={() => window.open(`https://econecta.io/${usuario.user_name}`, '_blank')}
//                     >
//                       Ir a Econecta
//                     </button>
//                   </td>
//                 </tr>
//               ))
//             ) : (
//               <tr>
//                 <td colSpan="6" className="text-center">No se encontraron usuarios.</td>
//               </tr>
//             )}
//           </tbody>
//         </table>
//       </div>
//     </div>
//   );
// };

// export default Dashboard;

import React, { useEffect, useState } from 'react';
import * as XLSX from 'xlsx';
import Select from 'react-select';
import { collection, query, where, getDocs, updateDoc, doc } from 'firebase/firestore';
import { db } from './firebase';
import './Dashboard.css';
import logoEmpresa from './assets/logo_empresa.png';
import logoCliente from './assets/logo.png';

const sucursalesOptions = [
  { value: 'America', label: 'America' },
  { value: 'Ávila', label: 'Ávila' },
  { value: 'Bello monte', label: 'Bello monte' },
  { value: 'Carlota Plaza', label: 'Carlota Plaza' },
  { value: 'CCCT', label: 'CCCT' },
  { value: 'Cerro Verde', label: 'Cerro Verde' },
  { value: 'Chacao', label: 'Chacao' },
  { value: 'Chuao', label: 'Chuao' },
  { value: 'Colinas de Tamanaco', label: 'Colinas de Tamanaco' },
  { value: 'Concresa', label: 'Concresa' },
  { value: 'Country Club', label: 'Country Club' },
  { value: 'El Cafetal', label: 'El Cafetal' },
  { value: 'El Hatillo', label: 'El Hatillo' },
  { value: 'El Rosal', label: 'El Rosal' },
  { value: 'La Boyera', label: 'La Boyera' },
  { value: 'La Castellana', label: 'La Castellana' },
  { value: 'La Lagunita', label: 'La Lagunita' },
  { value: 'La Tahona', label: 'La Tahona' },
  { value: 'Las Mercedes', label: 'Las Mercedes' },
  { value: 'Los Campitos', label: 'Los Campitos' },
  { value: 'Los Chorros', label: 'Los Chorros' },
  { value: 'Los Dos Caminos', label: 'Los Dos Caminos' },
  { value: 'Los Naranjos VIP', label: 'Los Naranjos VIP' },
  { value: 'Los Ruices', label: 'Los Ruices' },
  { value: 'Los Samanes', label: 'Los Samanes' },
  { value: 'Macaracuay', label: 'Macaracuay' },
  { value: 'Multicentro', label: 'Multicentro' },
  { value: 'Parque Cristal', label: 'Parque Cristal' },
  { value: 'Paseo Vip El Hatillo', label: 'Paseo Vip El Hatillo' },
  { value: 'Piramide Invertida', label: 'Piramide Invertida' },
  { value: 'Prados del Este', label: 'Prados del Este' },
  { value: 'Santa Paula', label: 'Santa Paula' },
  { value: 'Terras Plaza', label: 'Terras Plaza' },
  { value: 'Terrazas del Avila', label: 'Terrazas del Avila' },
  { value: 'Torre Humboldt', label: 'Torre Humboldt' },
  { value: 'Caurimare', label: 'Caurimare' },
  { value: 'Pampatar', label: 'Pampatar'},
  { value: 'Master', label: 'Master'}
  // ... (el resto de las opciones)
];

const Dashboard = ({ user, onLogout }) => {
  const [usuarios, setUsuarios] = useState([]);
  const [filteredUsuarios, setFilteredUsuarios] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [subtitleOptions, setSubtitleOptions] = useState([]);
  const [selectedSubtitle, setSelectedSubtitle] = useState(null);
  const [saving, setSaving] = useState(false);

  // Obtener usuarios desde Firestore
  useEffect(() => {
    const fetchUsuarios = async () => {
      try {
        const q = query(
          collection(db, "users"),
          where("user_name", ">=", "rentahouse"),
          where("user_name", "<", "rentahouse" + "\uf8ff")
        );

        const querySnapshot = await getDocs(q);
        const usuariosData = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));

        setUsuarios(usuariosData);
        setFilteredUsuarios(usuariosData);

        // Extraer subtítulos únicos
        const uniqueSubtitles = Array.from(
          new Set(usuariosData.map((user) => user.perfil?.subtitle).filter(Boolean))
        ).map((subtitle) => ({ value: subtitle, label: subtitle }));

        setSubtitleOptions(uniqueSubtitles);
      } catch (err) {
        console.error("Error al obtener los usuarios:", err);
        setError("Error al obtener los usuarios.");
      } finally {
        setLoading(false);
      }
    };

    fetchUsuarios();
  }, []);

  const handleSaveSucursal = async (userId, selectedSucursal) => {
    setSaving(true);
    try {
      const userDoc = doc(db, 'users', userId);
      await updateDoc(userDoc, { sucursal: selectedSucursal.value });
      alert('Sucursal guardada exitosamente.');
    } catch (err) {
      console.error("Error al guardar la sucursal:", err);
      alert("Error al guardar la sucursal.");
    } finally {
      setSaving(false);
    }
  };

  const formatDate = (timestamp) => {
    if (!timestamp) return 'N/A';
    const date = new Date(timestamp.seconds * 1000);
    return date.toLocaleString();
  };

  const handleSearch = (e) => {
    const term = e.target.value.toLowerCase();
    setSearchTerm(term);
    filterUsuarios(term, searchTerm);
  };

  const handleSubtitleChange = (selectedOption) => {
    setSelectedSubtitle(selectedOption);
    filterUsuarios(searchTerm, selectedOption);
  };

  const filterUsuarios = (term, subtitleOption) => {
    let filtered = usuarios;

    if (term) {
      filtered = filtered.filter(
        (usuario) =>
          (usuario.email && usuario.email.toLowerCase().includes(term)) ||
          (usuario.user_name && usuario.user_name.toLowerCase().includes(term)) ||
          (usuario.display_name && usuario.display_name.toLowerCase().includes(term))
      );
    }

    if (subtitleOption) {
      filtered = filtered.filter(
        (usuario) => usuario.perfil?.subtitle === subtitleOption.value
      );
    }

    setFilteredUsuarios(filtered);
  };

  const exportToExcel = () => {
    // Preparar los datos
    const dataToExport = filteredUsuarios.map(usuario => ({
      Correo: usuario.email,
      'Nombre de Usuario': usuario.user_name,
      Cargo: usuario.perfil?.subtitle || 'N/A',
      Nombre: usuario.display_name,
      'Fecha de Creación': usuario.created_time ? formatDate(usuario.created_time) : 'N/A',
      Sucursal: usuario.sucursal || 'N/A',
    }));
  
    // Crear una hoja de trabajo
    const worksheet = XLSX.utils.json_to_sheet(dataToExport);
  
    // Crear un libro de trabajo y agregar la hoja de trabajo
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Usuarios');
  
    // Generar el archivo Excel y desencadenar la descarga
    XLSX.writeFile(workbook, 'usuarios_rentahouse.xlsx');
  };
  return (
    <div className="dashboard-container">
      {/* Header */}
      <header className="dashboard-header">
        <img src={logoEmpresa} alt="Logo Empresa" className="header-logo" />
        <div className="search-container">
          <input
            type="text"
            placeholder="Buscar usuarios"
            value={searchTerm}
            onChange={handleSearch}
            className="search-input small-search"
            aria-label="Buscar usuarios"
          />
          <Select
            options={subtitleOptions}
            placeholder="Filtrar por cargo"
            value={selectedSubtitle}
            onChange={handleSubtitleChange}
            className="filter-select"
            isClearable
          />
        </div>
        <img src={logoCliente} alt="Logo Cliente" className="header-logo2" />
      </header>

      {/* Contenido */}
      <div className="dashboard-content">
        <h3>Lista de Usuarios Rentahouse</h3>
        <button className="export-button" onClick={exportToExcel}>
          Descargar en Excel
        </button>
        
        <p>Total de usuarios: {filteredUsuarios.length}</p>
        <table className="usuarios-table">
          <thead>
            <tr>
              <th>Correo</th>
              <th>Nombre de Usuario</th>
              <th>Cargo</th>
              <th>Nombre</th>
              <th>Fecha de Creación</th>
              <th>Sucursal</th>
              <th>Acción</th>
            </tr>
          </thead>
          <tbody>
            {filteredUsuarios.length > 0 ? (
              filteredUsuarios.map((usuario) => (
                <tr key={usuario.id}>
                  <td>{usuario.email}</td>
                  <td>{usuario.user_name}</td>
                  <td>{usuario.perfil.subtitle}</td>
                  <td>{usuario.display_name}</td>
                  <td>
                    {usuario.created_time
                      ? formatDate(usuario.created_time)
                      : "N/A"}
                  </td>
                  <td>
                    <Select
                      options={sucursalesOptions}
                      placeholder="Selecciona una sucursal"
                      defaultValue={
                        usuario.sucursal
                          ? { value: usuario.sucursal, label: usuario.sucursal }
                          : null
                      }
                      onChange={(option) =>
                        handleSaveSucursal(usuario.id, option)
                      }
                      isDisabled={saving}
                    />
                  </td>
                  <td>
                    <button
                      className="action-button"
                      onClick={() =>
                        window.open(
                          `https://econecta.io/${usuario.user_name}`,
                          "_blank"
                        )
                      }
                    >
                      👀
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="6" className="text-center">
                  No se encontraron usuarios.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Dashboard;
