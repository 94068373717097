// src/firebase.js
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyAhbG15T88gBO1W6cobpSTiban3Wy_9Fx0",
  authDomain: "somosnano-185ed.firebaseapp.com",
  projectId: "somosnano-185ed",
  storageBucket: "somosnano-185ed.appspot.com",
  messagingSenderId: "460484796640",
  appId: "1:460484796640:web:79d813740dbe90ba37b085"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export { db };
